<template>
  <el-container style="width: 100%; height: 100%">
    <el-header style="padding: 0px">
      <div class="header">
        <ul class="header-left">
          <transition name="el-zoom-in-bottom">
            <div class="logo" :class="{ islogo: isCollapse }">
              <img src="@/assets/login/logo.png" alt="" v-show="!isCollapse" />
              <!-- <transition name="el-zoom-in-bottom"> -->
              <img
                style="width: 40px; margin-left: 10px; height: 40px"
                src="@/assets/login/logo1.jpg"
                alt=""
                v-show="isCollapse"
              />
              <!-- </transition> -->
            </div>
          </transition>
          <div
            class="toggle"
            :class="{ isCollapse: isCollapse }"
            @click="isCollapse = !isCollapse"
          >
            <div>
              <span style="display: flex; align-items: center" v-if="isCollapse"
                ><i class="el-icon-s-unfold"></i>
              </span>
              <span style="display: flex; align-items: center" v-else
                ><i class="el-icon-s-fold"></i> 隐藏菜单</span
              >
            </div>
          </div>
        </ul>
        <div class="header-right">
          <div class="tips">
            <span> 你好，欢迎{{ sessio }} </span>
          </div>
          <div class="rightBox">
            <div @click="userRoute">
              <span style="font-size: 18px">首页</span>
            </div>
            <div class="rightBox1">
              <el-popover placement="bottom" width="240" trigger="click">
                <div>
                  <div class="rightBox-top">
                    <!-- <el-image
                      style="width: 60px; height: 60px"
                      :src="avatar"
                    ></el-image> -->
                    <!-- <el-avatar shape="square" :size="60" :fit="fit" :src="avatar"></el-avatar> -->
                    <!-- <el-avatar size="number" ></el-avatar> -->
                    <!-- <img  alt="" /> -->
                    <div style=" ">
                      <p>{{ sessio }}</p>
                      <!-- <p>{{ username }}</p> -->
                    </div>
                  </div>
                  <div class="ziliao">
                    <el-button
                      plain
                      style="
                        font-size: 18px;
                        margin-bottom: 10px;
                        padding-left: 12px;
                        background: #fff;
                        border: 0;
                        font-weight: 900;
                      "
                      @click="skipRoute"
                    >
                      用户中心
                    </el-button>
                    <!-- <el-button
                      plain
                      style="
                        font-size: 18px;
                        margin-bottom: 10px;
                        padding-left: 12px;
                        background: #fff;
                        border: 0;
                        font-weight: 900;
                      "
                      @click="userRoute"
                    >
                      首页
                    </el-button> -->
                  </div>

                  <el-button style="width: 100%" @click="RealName"
                    >退出系统</el-button
                  >
                </div>
                <span style="font-size: 18px; border: none" slot="reference"
                  >我的</span
                >
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </el-header>
    <el-container class="nav">
      <!-- <div>12312312312312</div> -->
      <!-- <el-aside   > -->
      <div v-show="$store.state.isShow == 1">
      <el-menu
        router
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :unique-opened="true"
        :collapse="isCollapse"
      >
       <el-menu-item   index="/homepage/homepage">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-menu-item   index="/information/Purchase/gkzb">
          <i class="el-icon-s-marketing"></i>
          <span slot="title">出函机构列表</span>
        </el-menu-item>
        <el-menu-item  index="/information/Purchase/negotiation">
          <i class="el-icon-s-tools"></i>
          <span slot="title">施工单位列表</span>
        </el-menu-item>
        <el-menu-item index="/information/Purchase/consultations">
          <i class="el-icon-user-solid"></i>
          <span slot="title">经办人</span>
        </el-menu-item>
        <el-menu-item index="/information/Purchase/invitation">
          <i class="el-icon-s-data"></i>
          <span slot="title">渠道</span>
        </el-menu-item>
        <!-- 工程信息 -->
        <!-- <el-submenu index="/information">
          <template slot="title">
							<i class="el-icon-film"></i>
							<span>机构管理</span>
						</template>
		  <el-submenu> -->
        <!-- <el-menu-item index="/information/Purchase/gkzb">
            <span slot="title">出函机构列表</span>
          </el-menu-item> -->
        <!-- <el-menu-item index="/information/Purchase/negotiation">
            <span slot="title">施工单位列表</span>
          </el-menu-item> -->
        <!-- <el-menu-item index="/information/Purchase/consultations">
            <span slot="title">经办人</span>
          </el-menu-item> -->
        <!-- <el-menu-item index="/information/Purchase/invitation">
            <span slot="title">渠道</span>
          </el-menu-item> -->
        <!-- </el-submenu> -->

        <el-submenu index="/staff">
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>工程信息</span>
          </template>
          <el-menu-item index="/staff/account">
            <span slot="title">招标列表</span>
          </el-menu-item>
          <el-menu-item index="/staff/jurisdiction">
            <span slot="title">中标列表</span>
          </el-menu-item>
        </el-submenu>

        <!-- 保函管理 -->
        <el-submenu index="/Guarantee">
          <template slot="title">
            <i class="el-icon-notebook-1"></i>
            <span>保函管理</span>
          </template>
          <el-menu-item index="/Guarantee/list">
            <span slot="title">保函列表</span>
          </el-menu-item>
          <el-menu-item index="/Guarantee/guarantee">
            <span slot="title">反担保列表</span>
          </el-menu-item>
        </el-submenu>
        <!-- 资质管理 -->
        <el-submenu index="/qualifications">
          <template slot="title">
            <i class="el-icon-s-promotion"></i>
            <span>施工监管</span>
          </template>
          <el-menu-item index="/qualifications/qlist">
            <span slot="title">施工进度列表</span>
          </el-menu-item>
        </el-submenu>
         <el-menu-item   index="/homepage/ranking">
          <i class="el-icon-menu"></i>
          <span slot="title">机构排行</span>
        </el-menu-item>
      </el-menu>
      </div>
      <!-- </el-aside> -->
      <el-main style="background: #f5f6fa">
        <div
          style="
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
          "
        >
         
            <router-view  style="flex:1"></router-view>
         
          <binad></binad>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { logout } from "@/api/personal";
import binad from "@/components/dinad.vue";
export default {
  components: {
    binad,
  },
  data() {
    return {
      value: [],
      isCollapse: false,
      sessio: sessionStorage.getItem("username"),
      username: sessionStorage.getItem("company_name"),
      avatar: sessionStorage.getItem("avatar"),
      cardname: sessionStorage.getItem("cardname"),
    };
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log("+++++", key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log("-----", key, keyPath);
    },
    // 企业资料
    skipRoute() {
      this.$router.push("/personal");
    },
    // 个人资料
    userRoute() {
      this.$router.push("/homepage/homepage");
    },
    RealName() {
      // this.$router.push("/RealName/PersonalRealname");
      this.$confirm("确定退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout().then((response) => {
            console.log(response);
            sessionStorage.removeItem("token");
            this.$router.push("/login");
          });
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {});
    },

    homePage() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped lang="scss">
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
.el-submenu__title {
  color: #1795ff !important;
  font-weight: bold;
}

.el-menu-item {
  //   width: 100%;
  //   /* color: #1795ff !important; */
  //   padding-left: 40px !important;
}

.left-item {
  padding-left: 22px !important;
}

::v-deep.el-menu-item.is-active {
  color: white !important;
  background-color: #1795ff !important;
  width: 100% !important;
  // border-radius: 2px !important;
  // border-radius: 8px 8px 8px 8px !important;
}
::v-deep .el-submenu {
  width: 100% !important;
}
.sjdp {
  width: 100vw;
  height: 100vh;
}

ul {
  list-style: none;
}

.homebutton {
  height: 44px;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.homeLess {
  width: 288px;
  height: 200px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* padding: 0 5%; */
  height: 60px;
  line-height: 60px;
  margin-bottom: 10px;
  /* min-width: 1200px; */
  /* margin-left: 200px; */
}

.header .header-left {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  // margin-left: -15px;
}

.header .header-right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.header .header-right .img1 {
  position: absolute;
  right: 60px;
  top: -3px;
  text-align: center;
  display: inline-block;
  height: 100%;
  font-size: 18px;
}

.header .header-right .img2 {
  position: absolute;
  right: 10px;
  top: 13px;
  width: 20px;
  height: 20px;
  border: none;
  font-size: 18px;
}

.header .header-right .img2 .img3 {
  position: absolute;
  left: -5px;
  bottom: -13px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) li{
  width: 220px;
//   overflow: auto;
  height: 100%;

  // min-height: 400px;
}

.nav {
  position: relative;
  height: calc(100vh - 60px);
  width: 100%;
  // height: 100%;
}

.el-main {
  padding: 10px 10px 0;
  box-sizing: border-box;
}

.toggle {
  position: fixed;

  left: 205px;
  text-align: center;
}

.isCollapse {
  left: 50px;
  //   top: 7px;
}

.toggle span {
  margin-left: 25px;
}

.logo {
  height: 100%;

  img {
    width: 229px;
    height: 100%;
    // margin-left: -20px;
  }
}

.islogo {
  img {
    width: 30px;
    height: 30px;
  }
}

::v-deep.el-icon-s-unfold,
.el-icon-s-fold {
  font-size: 26px !important;
}

.bottomBox {
  // margin-top: 3px;
  justify-content: center;
  display: flex;
  width: 100%;
  align-content: center;
  height: 58px;
  padding: 9px 0;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #999;
  background: #fff;
}

.tips {
  width: 80%;
  // padding-right: 120px;
  box-sizing: border-box;
  text-align: right;
}

.rightBox {
  width: 140px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
}

.rightBox-top {
  display: flex;
  margin-bottom: 8px;
  padding: 12px;
  justify-content:center;

  img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: left;
      // margin: 10px 0;
      margin-bottom: 10px;
      width: 100%;
      font-size: 16px;
    }
  }
}

.ziliao {
  display: flex;
  justify-content: center;
  padding-right: 12px;

  margin-top: -10px;
  margin-bottom: 10px;
}

.wwwscrolly::-webkit-scrollbar,
.wwwscroll::-webkit-scrollbar,
.wwwscrollx::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
</style>
