<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default{
        watch: {
		$route(to, from) {
			console.log(to.path);
			if (to.path == '/zbxq' || to.path == '/bhxq' || to.path=="/fdbxq" || to.path =="/daihouguanli/detail" ||
            to.path ==="/personal" || to.path =="/zhaoxq"
            ) {
				// this.isShow = false
                this.$store.dispatch("AsyicOPENSHOW", false);
			} else {
				// this.isShow = true
                this.$store.dispatch("AsyicOPENSHOW", true);
			}
		}
	},
	}
</script>
<style lang="scss">
*{margin:0;padding:0}li{list-style:none}img{vertical-align:top;border:none}
/*清除默认样式*/
html, body, div, ul, li, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ol, form, input, textarea, th, td, select {
    margin: 0;
    padding: 0;
}
*{box-sizing: border-box;}
html, body {
    height: 100%;
    width: 100%;
}
#app{
    width: 100%;
    height: 100%;
}
.mb8 .el-button {
  padding: 8px 20px !important;
   display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.mb8 .el-col-18 {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.xinde .el-table__body-wrapper {
  overflow: auto;
    position: relative;
    height: calc(100% - 50px) !important ;
}
/* 搜索 */
.mb8 .seach {
  display: flex;
  align-items: center;
  border: 1px solid #999999;
  padding: 8px 0;
  height: 33px;
  box-sizing: border-box;
}
.el-menu--collapse {
    // width: 64px !important;
}
.mb8 .seach .el-input {
  border: none;
}
.mb8 .el-input__inner{
	border: 0;
	height: 30px;
}
el-button[data-v-43d0761f] {
    display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}
body {
    font-family: "Microsoft YaHei";
    font-size:14px;
    color:#333;
}
h1, h2, h3, h4, h5, h6{font-weight:normal;}
ul,ol {
    list-style: none;
}

img {
    border: none;
    vertical-align: middle;
}

a {
    text-decoration: none;
    color: #232323;
}

table {
    border-collapse: collapse;
    table-layout: fixed;
}

input, textarea {
    outline: none;
    border: none;
}

textarea {
    resize: none;
    overflow: auto;
}
	nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
</style>
