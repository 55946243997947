/**
 * 封装 axios 请求模块
 */
//  import router from '../router/router';
import axios from "axios";
//http://192.168.0.198:8081/api/index/bhlist

const request = axios.create({
  //  baseURL: "https://dagent.hnzyxsbh.com" https://manage.hnzyxsbh.com// 基础路径
  baseURL: "https://api.hnzyxsbh.com/", // 基础路径
});
// const that=this
// // //3. 定义前置拦截器，请求拦截器，请求发送出去之前触发的
request.interceptors.request.use(
  (config) => {
    // console.log("config",config);

    //config 接口请求的配置信息
    // config.headers.Authorization={
    // }
    config.headers.token = sessionStorage.getItem("token");
    // console.log("config: " , config);
    return config;
  },
  (error) => {
    //报错的是时候抛出一个报错的信息
    return Promise.reject(error.response.status);
  }
);

//4. 定义后置拦截器,响应拦截器, 服务器响应回来数据之前触发，
request.interceptors.response.use(
  (response) => {
    // console.log("response:",response.status);

    //响应回来的数据操作
    return response;
  },
  (error) => {
    // console.log(error.response.status);
    if (error.response.status == "401") {
      // 命名的路由  params ，地址栏不会暴露（userId）
      // router.push({name:'name',params:{index:401}})
      //params获取

      // router.push({ path: '/name', query: { myId: 401 }})
      return;
    }
    //报错的是时候抛出一个报错的信息
    // return Promise.reject(error);
  }
);

export default request;
