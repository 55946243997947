import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	component: HomeView,
	redirect:"/homepage/homepage",
	children: [
		//首页
		{
			path: "homepage/homepage",
			name: "homepage",
			component: () => import("../views/homepage/HomepageView.vue"),
			meta: { title: "首页" },
		},
		{
			path: "homepage/ranking",
			name: "ranking",
			component: () => import("../views/homepage/Rankinglist.vue"),
			meta: { title: "排行" },
		},
		//  工程信息 //采购公告
		{
			path: "information/Purchase",
			name: "Purchase",
		},
		//公开招标
		{
			path: "information/Purchase/gkzb",
			name: "gkzb",
			component: () => import("../views/information/Purchase/GkzbView.vue"),
			meta: { title: "出函机构列表" },
		},
		//竞争性谈判公告
		{
			path: "information/Purchase/negotiation",
			name: "negotiation",
			component: () => import("../views/information/Purchase/NegotiationView.vue"),
			meta: { title: "施工单位列表" },
		},
		//竞争性磋商公告
		{
			path: "information/Purchase/consultations",
			name: "consultations",
			component: () => import("../views/information/Purchase/ConsultationsView.vue"),
			meta: { title: "经办人" },
		},
		{
			path: "/bhxq",
			name: "bhxq",
			component: () => import("../views/Guarantee/baohan/bhxq.vue"),
			meta: { title: "保函详情" },
		  },
		  {
			path: "/zhaoxq",
			name: "zhaoxq",
			component: () => import("../views/Guarantee/baohan/zhaoxq.vue"),
			meta: { title: "保函详情" },
		  },
		  {
			path: "/daihouguanli/detail",
			name: "daihouguanli/detail",
			component: () => import("../views/Guarantee/baohan/detail.vue"),
		  },
		  {
			path: "/fdbxq",
			name: "fdbxq",
			component: () => import("../views/Guarantee/baohan/fdbxq.vue"),
			meta: { title: "反担保保函详情" },
		  },
		{
			path: "/zbxq",
			name: "zbxq",
			component: () => import("../views/Guarantee/baohan/zbxq.vue"),
			meta: { title: "公告详情" },
		  },
		//邀请招标公告
		{
			path: "information/Purchase/invitation",
			name: "invitation",
			component: () => import("../views/information/Purchase/InvitationView.vue"),
			meta: { title: "渠道" },
		},
		//询价公告
		{
			path: "information/Purchase/inquiry",
			name: "inquiry",
			component: () => import("../views/information/Purchase/InquiryView.vue"),
			
		},
		//单一来源公告
		{
			path: "information/Purchase/source",
			name: "source",
			component: () => import("../views/information/Purchase/SourceView.vue"),
			
		},
		//资格预审
		{
			path: "information/Purchase/Prequalification",
			name: "Prequalification",
			component: () => import("../views/information/Purchase/PrequalificationView.vue"),
			
		},
		//  工程信息 //结果公告
		{
			path: "information/result",
			name: "result",
		},
		{
			path: "information/result/bid",
			name: "bid",
			component: () => import("../views/information/result/BidView.vue"),
			
		},
		{
			path: "information/result/deal",
			name: "deal",
			component: () => import("../views/information/result/DealView.vue"),
		
		},

		//员工管理
		{
			path: "staff/account",
			name: "account",
			component: () => import("../views/staff/AccountView.vue"),
			meta: { title: "招标列表" },
		},

		{
			path: "staff/jurisdiction",
			name: "jurisdiction",
			component: () => import("../views/staff/JurisdictionfView.vue"),
			meta: { title: "中标列表" },
		},


		//保函管理

		{
			path: "Guarantee/apply",
			name: "apply",
			component: () => import("../views/Guarantee/ApplyView.vue")
		},
		{
			path: "Guarantee/list",
			name: "list",
			component: () => import("../views/Guarantee/ListView.vue"),
			meta: { title: "保函列表" },
		},
		{
			path: "Guarantee/guarantee",
			name: "guarantee",
			component: () => import("../views/Guarantee/GuaranteeView.vue"),
			meta: { title: "反担保列表" },
		},
		// 财务管理
		{
			path: "finance/payment",
			name: "payment",
			component: () => import("../views/finance/PaymentView.vue")
		},
		{
			path: "finance/refund",
			name: "refund",
			component: () => import("../views/finance/RefundView.vue")
		},
		{
			path: "finance/invoice",
			name: "invoice",
			component: () => import("../views/finance/InvoiceView.vue")
		},
		// 经办人
		{
			path: "qualifications/qlist",
			name: "qlist",
			component: () => import("../views/qualifications/QlistView.vue"),
			meta: { title: "施工进度列表" },
		},
		{
			path: "qualifications/examine",
			name: "examine",
			component: () => import("../views/qualifications/ExamineView.vue")
		},{
			path: '/personal',
			name: 'PersonalView',
			component: () => import("../views/PersonalView.vue"),
		},

	]
},
{
	path: '/login',
	name: 'loginView',
	component: () => import("../views/LoginView.vue"),
},
{
	path: '/register',
	name: 'registerView',
	component: () => import("../views/RegisterView.vue"),
},





//详情页
{
	path: '/details',
	name: 'DetailsView',
	component: () => import("../views/DetailsView.vue"),
},
{
	path: '/BidSecurity/SelectALot',
	name: 'SelectALot',
	component: () => import("../views/BidSecurity/SelectALot.vue"),
},
//2
{
	path: '/BidSecurity/financialInstitution',
	name: 'financialInstitution',
	component: () => import("../views/BidSecurity/financialInstitution.vue"),
},
{
	path: '/BidSecurity/Submission',
	name: 'SubmiSsion',
	component: () => import("../views/BidSecurity/Submission.vue"),
},
//4
{
	path: '/BidSecurity/bidFrom',
	name: 'bidFrom',
	component: () => import("../views/BidSecurity/bidFrom.vue"),
},
//5
{
	path: '/BidSecurity/ElectronicProtocol',
	name: 'ElectronicProtocol',
	component: () => import("../views/BidSecurity/ElectronicProtocol.vue"),
},
//6
{
	path: '/BidSecurity/BidPayment',
	name: 'BidPayment',
	component: () => import("../views/BidSecurity/BidPayment"),
},
//7
{
	path: '/BidSecurity/puBlic',
	name: 'puBlic',
	component: () => import("../views/BidSecurity/puBlic.vue"),
},
//实名认证
{
	path: '/RealName/PersonalRealname',
	name: 'PersonalRealname',
	component: () => import("../views/RealName/PersonalRealname.vue"),
},
{
	path: '/RealName/RealnameInformation',
	name: 'RealnameInformation',
	component: () => import("../views/RealName/RealnameInformation.vue"),
},
]
const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	if (to.path !== '/login' && to.path !== '/register' && !sessionStorage.getItem('token')){	
		next('/login')
	}
	else{
		next()
	}

	if (to.name == 'login') {
		console.log(to.name);
	}
	next()
  })





export default router
