import request from "@/utils/request"



//个人中心
export const grzx = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/manage/index/userCenter",
    });
  };
  //退出登录
export const out = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/manage/index/logout",
    });
  };
// 企业资质
export const zizhi = () => {

    return request({
      method: "POST",
      url: "/manage/company/zizhi",
    });
  };
  // 企业业绩
export const yeji = () => {

    return request({
      method: "POST",
      url: "/manage/company/yeji",
    });
  };
  //公章使用记录
  export const seal = () => {

    return request({
      method: "POST",
      url: "/manage/index/gongsizhang",
    });
  };
  //法人章使用记录
  export const corporate = () => {

    return request({
      method: "POST",
      url: "/manage/index/farenzhang",
    });
  };
  //修改密码
  export const editPassword = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/manage/baohan/password",
    });
  };
  //退出
  export const logout = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/manage/baohan/logout",
    });
  };

  export const ranking = (pass) => {

    return request({
      method: "POST",
      data:pass,
      url: "/manage/baohan/ranking",
    });
  };


